<template>
	<div>
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg-m" />
		<div class="newList main">
			<view class="list-item alcenter" v-for="(item,index) in list" :key="index" @click="jump(item.id)">
				<img :src="proxy.$axios.imgUrl + item.images" class="left" />
				<div class="content">
					<view class="title">{{item.title}}</view>
					<view class="subhead">{{item.synopsis}}</view>
					<view class="timer flex-layout">
						<view>{{item.date}},{{item.year}}</view>
						<img src="../../assets/image/ic_morejt.png" class="more" />
					</view>
				</div>
			</view>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		getCurrentInstance,
		ref
	} from 'vue';
	import {
		useRouter
	} from 'vue-router'
	import EventBus from "../../assets/common/event-bus.js"

	const router = useRouter()

	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		EventBus.emit("mgheadr", 4);
		getList()
		getTopimg()
	})

	function jump(id) {
		router.push({
			path: '/newsdetail-m',
			query: {
				id
			}
		})
	}

	const list = ref([])

	const page = ref(1)

	async function getList() {
		let res = await proxy.$axios.get('/api/index/getNewsData', {
			page: page.value
		})
		if (Array.isArray(res.data) && res.data.length) {
			list.value = [...list.value, ...res.data]
			page.value++
		}
	}

	const topImg = ref('')

	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: 'news'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg-m {
		width: 100%;
		height: auto;
		display: block;
	}

	.list-item {
		padding: 0.32rem 0;
		border-bottom: 1px solid #EEEEEE;
	}

	.list-item .left {
		width: 2.44rem;
		height: 1.62rem;
	}

	.list-item .content {
		flex: 1;
		margin-left: 0.25rem;
	}

	.list-item .content .title {
		font-weight: 500;
		font-size: 0.26rem;
		color: #000000;
		line-height: 0.326rem;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.list-item .content .subhead {
		margin-top: 0.16rem;
		font-weight: 400;
		font-size: 0.22rem;
		color: #666666;
		line-height: 0.32rem;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.list-item .content .timer {
		font-size: 0.22rem;
		color: #999999;
		line-height: 0.22rem;
		margin-top: 0.20rem;
	}

	.list-item .content .timer>img {
		width: 0.48rem;
		height: 0.24rem;
		display: block;	
	}
	
	
</style>